<template>
    <div>
        <v-row>
            <v-col cols="12">
                <v-simple-table dark dense border="left" fixed-header>
                    <template v-slot:default>
                        <thead>
                            <tr class="font-weight-bold">
                                <th class="text-left" scope="folio">Folio de pago</th>
                                <th class="text-left" scope="paymentDate">Fecha de pago</th>
                                <th class="text-left" scope="paymentAmount">Monto pagado</th>
                                <th class="text-left" scope="paymentMethod">Metodo de pago</th>
                                <th class="text-left" scope="operationNumber">Numero de operación</th>
                                <th class="text-left" scopte="ticketFolio">Folio de venta</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="(item, index) in payments" :key="index">
                                <td v-text="item.ventaPago.folio"></td>
                                <td v-text="item.ventaPago.paymentDateNatural"></td>
                                <td v-text="item.payedAmountNatural"></td>
                                <td v-text="item.ventaPago.paymentMethodNatural"></td>
                                <td v-text="item.ventaPago.operationNumber"></td>
                                <td v-text="item.venta.ticketFolio"></td>
                            </tr>
                            <tr class="font-weight-black">
                                <td colspan="1"></td>
                                <td>Total abonado:</td>
                                <td v-text="totalAbonadoNatural"></td>
                            </tr>
                            <tr class="font-weight-black" v-if="ventaId">
                                <td colspan="1"></td>
                                <td>Total venta:</td>
                                <td v-text="totalVentaNatural"></td>
                            </tr>
                            <tr class="font-weight-black" v-if="ventaId">
                                <td colspan="1"></td>
                                <td>Saldo:</td>
                                <td v-text="totalSaldoNatural"></td>
                            </tr>
                        </tbody>
                    </template>
                </v-simple-table>
            </v-col>
            <v-col cols="12" sm="12" md="12" xs="12">
                <v-chip class="mountPayment" large label text-color="white"
                color="primary">
                    <p class="text-uppercase mt-4 white--text font-weight-bold text-h5">
                        Total pagado: {{ totalAbonadoNatural }}
                    </p>
                </v-chip>
            </v-col>
        </v-row>                
        <v-list-item class="grow mt-3">
            <v-row align="center" justify="end">
                <v-tooltip left color="success">
                    <template v-slot:activator="{ on, attrs }">
                        <v-btn class="ma-2" color="teal darken-3" v-bind="attrs" v-on="on" dark
                            @click="downloadExcel">
                            <v-icon dark left>fas fa-file-excel</v-icon>Reporte a excel
                        </v-btn>
                    </template>
                    <span>Descargar reporte de pago</span>
                </v-tooltip>
                <v-btn class="ma-2" color="blue darken-4"" dark @click.prevent="previewPDF">
                    <v-icon dark left>fas fa-file-pdf</v-icon>Ver PDF
                </v-btn>
            </v-row>
        </v-list-item>
        <PreviewPDF :pdfSource="pdfSource" :pdfBlob="pdfBlob"
            :title-pdf="'PAGO ' + pago.folio"
            :handlePdfModal="handlePdfModal" :dialog="showPdf" v-if="showPdf">
        </PreviewPDF>
    </div>
</template>
<script>
import PreviewPDF from '@/components/shared/PreviewPDF.vue';
import VentaPagoDataService from '@/service/venta/pago/VentaPagoDataService';
import VentaPagoDetalleDataService from '@/service/venta/pago/VentaPagoDetalleDataService';
import { getFormatCurrency } from '@/utils/CurrencyUtil';

export default {
    name: 'DetailTablePayment',
    props: {
        payments: { type: Array },
        detailsVenta: { type: Object, default : () => ({}) },
        ventaId: { type: Number, default: 0 },
        pago: { type: Object, default: () => ({}) },
        ventaPagoId: { type: Number, default: 0 }
    },
    components: { PreviewPDF },
    data() {
        return {
            pdfSource: '',
            pdfBlob: null,
            showPdf: false,
        }
    },
    methods: {
        downloadExcel() {
            VentaPagoDetalleDataService.downloadExcelPagoDetalle({
                ventaId: this.ventaId,
                ventaPagoId: this.ventaPagoId
            }).then( response => {
                const url = window.URL.createObjectURL(new Blob([response.data]));
                const link = document.createElement('a');
                link.href = url;
                if( this.ventaId !== 0 ) {
                    link.setAttribute('download', `Detalles_de_pagos_${this.ticketVenta}.xlsx`);
                } else {
                    link.setAttribute('download', `Detalles_de_pagos_${this.ticketVentaPago}.xlsx`);
                }
                document.body.appendChild(link);
                link.click();
                link.remove();
            }).catch(err => {
                this.$swal.fire({ position: 'top-end', icon: 'error', html: 'Error al generar el reporte' + err.response.data, showConfirmButton: false, timer: 3500 });
            });
        },
        previewPDF() {
            VentaPagoDataService.printPayment(this.pago.id).then( response => {
                this.pdfBlob = new Blob([response.data], { type: 'application/pdf' })
                this.pdfSource = URL.createObjectURL(this.pdfBlob)
                this.showPdf = true
            }).catch( error => {
                this.$swal.fire({ position: 'top-end', icon: 'error', html: 'Error al generar el reporte' + error.response.data, showConfirmButton: false, timer: 3500 });
            });
        },
        handlePdfModal(){
            this.showPdf = !this.showPdf
            this.cleanupPdf()
        },
        cleanupPdf(){
            if(this.pdfSource){
                URL.revokeObjectURL(this.pdfSource)
                this.pdfSource = null
            }
            this.pdfBlob = null
        }
    },
    computed: {
        ticketVenta() {
            return this.detailsVenta.ticketFolio || ""
        },
        ticketVentaPago() {
            return this.pago.folio || ""
        },
        totalVenta() {
            return this.detailsVenta.total || 0
        },
        totalVentaNatural() {
            return getFormatCurrency(this.totalVenta, '$ ');
        },
        totalAbonado() {
            return this.payments.reduce((acc, item) => acc + item.payedAmount, 0);
        },
        totalAbonadoNatural() {
            return getFormatCurrency(this.totalAbonado, '$ ');
        },
        totalSaldo() {
            return this.totalVenta - this.totalAbonado;
        },
        totalSaldoNatural() {
            return getFormatCurrency(this.totalSaldo, '$ ');
        }
    }
}
</script>
<style>
    .mountPayment {
        width: 100%;
        justify-content: center;
    }
</style>